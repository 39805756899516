.op-bubble
  @include indicator-bubble

  &_alt_highlighting
    background: #878787

  &_mini
    width: 12px
    height: 12px
    padding: 0
