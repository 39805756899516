// A selector that checks whether we are running in a test environment
$spec-active-selector: '.env-test'

$work-package--start-date-display-field-padding-left: 1.5rem
// All different input types and textarea
$text-input-selectors: 'input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea'

// A global used variable for all labels
$form-label-fontsize: var(--body-font-size) !default

// These breakpoint widths ( -1) were taken from Primer
// The substraction was needed since they have a "min-width" approach for their breakpoints and we have a "max-width"
$breakpoint-sm: 543px
$breakpoint-md: 767px
$breakpoint-lg: 1011px
$breakpoint-xl: 1279px
