.tp-assignee
  display: flex
  max-width: 100%

  &--principal
    max-width: 100%
    min-width: 0 // See: https://css-tricks.com/flexbox-truncated-text/
    flex-grow: 1
    flex-shrink: 1

  &--remove
    border-radius: 50%
    margin: 0
    padding: 0
    padding-left: 0.5rem
    flex-grow: 0
    flex-shrink: 0
    width: 0
    border: 0
    background: transparent
    pointer-events: none
    opacity: 0

  @at-root &--remove:focus, &:hover &--remove, #{$spec-active-selector} &--remove
    width: unset
    cursor: pointer
    opacity: 1
    pointer-events: all
