.work-packages--resizer
  position: absolute
  top: 0
  bottom: 0
  height: 100%
  cursor: col-resize
  color: var(--light-gray)
  font-size: 14px

  &:before
    position: relative
    top: 50%

.work-packages--tabletimeline--timeline--resizer
  .work-packages--resizer
    left: initial
    z-index: 10
    &:before
      left: initial
