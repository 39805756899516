.invite-user-form
  display: grid
  grid-template-columns: 1fr auto auto
  grid-template-areas: "invitee permission submit" "userLimitWarning userLimitWarning userLimitWarning" "errors errors errors" "userSelectedWarning userSelectedWarning userSelectedWarning"
  //
  //  There's no multiline tokens support in SASS due to the indentation parsing engine.
  // For a quick grok of the template areas structure above, here's a diagram:
  //
  // invitee             permission          submit
  // userLimitWarning    userLimitWarning    userLimitWarning
  // userSelectedWarning userSelectedWarning userSelectedWarning
  //
  grid-column-gap: 0.5rem
