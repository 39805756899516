.work-packages--new
  .attributes-group.description-group
    // Remove bottom border
    .attributes-group--header
      border-bottom: none
      margin-bottom: 0

    // disable the resize grabber
    // as its resized automatically by content (height)
    textarea
      resize: none
