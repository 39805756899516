.op-uc-table
  break-inside: avoid
  border-collapse: collapse
  border-spacing: 0
  width: 100%
  border: 1px solid var(--table-border-color)

  &--row
    &:not(:last-child)
      border-bottom: 1px solid var(--table-border-color)

    @at-root .op-uc-container:not(.op-uc-container_editing) &:hover
      background: rgba(26, 103, 163, 0.05)

  &--cell
    text-align: left
    min-width: 3em
    padding: 0.75rem

    &:not(:last-child):not([colspan])
      border-right: 1px solid var(--table-border-color)

    @include user-content-children

    @at-root .op-uc-container_editing &:hover
      background: rgba(26, 103, 163, 0.05)

    &_head
      background: rgb(242, 242, 242)
      background-clip: padding-box
      border-bottom: 1px solid rgba(0, 0, 0, 0.1)

    &.ck-editor__editable_selected
      background: rgba(26, 103, 163, 0.1)
