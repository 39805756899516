.op-logo
  width: 230px
  height: var(--header-height)

  &--link
    margin-top: 12px
    width: 100%
    display: block
    height: 30px
    background: no-repeat 20px 0
    text-indent: -9999em
    background-position: center
    background-size: contain
    background-repeat: no-repeat

  @media screen and (max-width: 850px)
    display: none
