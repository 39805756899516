body.zen-mode
  #main-menu
    display: none
  #content-wrapper
    margin-left: 0px
    height: 100vh
    width: 100vw

  #main
    top: 0px
    height: 100%
    grid-template-columns: auto

  &.controller-wiki #content
    margin: 0 auto

    @media only screen and (max-width: $breakpoint-xl)
      max-width: 90vw

    @include breakpoint(xl)
      max-width: 60vw
