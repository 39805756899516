.op-form
  &--submit
    margin: 0
    margin-bottom: 1.5rem
    display: flex

  &,
  &--fieldset
    display: flex
    flex-direction: column
    align-items: flex-start

    > *
      max-width: 100%
      width: 50rem

    &--section-header,
    > .op-form--fieldset,
    > .op-form--field,
    > .spot-form-field,
    > .spot-selector-field,
    > .op-option-list,
    > .op-highlighted-input,
    > .button
      &:not(:last-child)
        margin-bottom: 1rem

  &--field
    .spot-form-field
      margin-bottom: 1rem
