.wp-new-top-row
  color: var(--content-link-color)
  display: flex
  font-size: 24px

  // Add some minor margin between active status and type field
  #wp-new-inline-edit--field-type,
  .work-packages--type-selector
    margin-left: 5px

  // Fix display left padding of type
  .inline-edit--display-field
    padding-left: 0 !important

  // Disable text decoration from toolbar span
  .inline-edit--display-field:hover
    text-decoration: none

  .wp-new-top-row--status,
  .wp-new-top-row--type
    .inline-edit--field
      min-width: 125px

  .work-packages--type-selector,
  .work-packages--status-selector
    .-active
      margin: 6px 6px 6px 0px

.work-packages--type-selector .-active
    margin: 6px 6px 0px 0px
