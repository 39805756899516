$user-avatar-width: 36px
$user-avatar-height: 36px
$user-avatar-medium-border-radius: 50%
$user-avatar-medium-width: 28px
$user-avatar-medium-height: 28px
$user-avatar-mini-border-radius: 50%
$user-avatar-mini-width: 20px
$user-avatar-mini-height: 20px

.op-avatar
  border-radius: var(--user-avatar-border-radius)
  width: $user-avatar-width
  // min-width was added because the avatar was getting squished as a flex-child
  min-width: $user-avatar-width
  height: $user-avatar-height
  color: white
  display: inline-block
  text-align: center
  vertical-align: middle
  cursor: inherit
  user-select: none
  // (Default height) 36 - 2(border pixels)
  line-height: 34px
  font-size: 16px
  border: 1px solid var(--avatar-border-color)

  // Fallback avatar if no img tag gets rendered
  &--fallback
    max-width: none

  &_mini
    border-radius: $user-avatar-mini-border-radius
    width: $user-avatar-mini-width
    min-width: $user-avatar-mini-width
    height: $user-avatar-mini-height
    // (mini height) 20 - 2(border pixels)
    line-height: 18px
    font-size: 10px

  &_medium
    border-radius: $user-avatar-medium-border-radius
    width: $user-avatar-medium-width
    min-width: $user-avatar-medium-width
    height: $user-avatar-medium-height
    // (medium height) 28 - 2(border pixels)
    line-height: 26px
    font-size: 13px

  // Different border styling for placeholders
  &_placeholder-user
    border: 1px dashed var(--body-font-color)
    background: none !important
    color: var(--body-font-color)

  // Different border styling for groups
  &_group
    border: 1px solid white
    box-shadow: 4px 0px 0px -1px #cccccc
