.work-packages--details-content
  // Override the top header in create mode
  &.-create-mode
    top: 0
    overflow-y: auto

// Details header containing switch icon and status|type row
.work-packages--new-details-header
  margin: 0.375em 0
  display: flex
  align-items: stretch

// Capitalize status name
.work-packages--status-selector
  .inline-edit--display-field
    text-transform: capitalize

// Full screen toggle indicator on the
// left side of the details container
.wp--details--switch-fullscreen-wrapper
  flex: 1
  text-align: right

.wp--details--switch-fullscreen
  color: var(--body-font-color)
  display: inline-block
  width: 20px
  cursor: pointer

  &:hover
    text-decoration: none

  // Align icon
  font-size: 0.9rem
  padding-top: 14px

  span:before
    padding: 0
