.op-select-footer
  display: block
  margin: 0
  padding: 0

  &--label
    cursor: pointer
    display: block
    background: transparent
    border: 0
    padding: 8px 10px
    line-height: 22px
    background-color: #fff
    color: rgba(0, 0, 0, 0.87)
    font-weight: var(--base-text-weight-bold)
    width: 100%
    text-align: left

    &:hover
      background-color: #f5faff
      color: #333
