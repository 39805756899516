.meeting-detail-participants
  visibility: collapse
  // The modal is rendered inside the mobile participant list element and it can be
  // triggered from both mobile and desktop views. The mobile participant list is hidden
  // when the desktop view is active, but the modal inside it should still be visible.
  .Overlay-backdrop--center
    visibility: visible

@media screen and (max-width: $breakpoint-sm)
  .meeting-detail-participants
    visibility: visible
